module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"wetainment.com","customDomain":"plausible.wetainment.com/js/plausible.js?original="},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wetainment blog/portfolio","short_name":"wetainment","start_url":"/","background_color":"#F4F2F3","theme_color":"#DC680B","display":"standalone","orientation":"portrait","icons":[{"src":"/ic_launcher_48.png","sizes":"48x48","type":"image/png"},{"src":"/ic_launcher_72.png","sizes":"72x72","type":"image/png"},{"src":"/ic_launcher_96.png","sizes":"96x96","type":"image/png"},{"src":"/ic_launcher_144.png","sizes":"144x144","type":"image/png"},{"src":"/ic_launcher_192.png","sizes":"192x192","type":"image/png"},{"src":"/ic_launcher_512.png","sizes":"512x512","type":"image/png"},{"src":"/ic_launcher_1024.png","sizes":"1024x1024","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    }]
